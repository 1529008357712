import room1 from 'content/room-1.json';
import room2 from 'content/room-2.json';
import room3 from 'content/room-3.json';
import room4 from 'content/room-4.json';
import room5 from 'content/room-5.json';
import room6 from 'content/room-6.json';
import { Rooms } from 'global';

export const ROOMS: Rooms = {
  room1, room2, room3, room4, room5, room6,
};

export const BUBBLE_TIMEOUT = 8000;
export const BUBBLE_TIMEOUT_2 = 5000;

// export const ZOOM_FACTOR = 3;
export const MAX_ZOOM: number = 2; // 64;
export const ZOOM_VALUES = [1, 3, 6];
export default { ROOMS };
