import React, { useEffect, useState, useCallback } from 'react';
import routes from 'routes';
import {
  Route, BrowserRouter, Routes, useLocation,
} from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { usePrevious } from 'components/global';
import Map2d from 'components/MapManager';
import scss from 'app.module.scss';
import './main.css';

const Switch = () => {
  const location = useLocation();
  const [start, setStart] = useState(false);
  const [directAnimation, setDirectAnimation] = useState(true);

  const displayRoute = useCallback((route :any, key :any) => {
    if (route.component === 'MapManager') {
      route.component = (
        <Map2d
          cache={{ ci: [], prevCILength: 0 }}
          onStart={() => {
            setDirectAnimation(true);
          }}
        />
      );
    }
    return (
      <Route
        key={key}
        path={route.path}
        element={route.component}
      />
    );
  }, []);

  const prevPathname: any = usePrevious(location.pathname);
  useEffect(() => {
    if (location.pathname === '/map' && prevPathname !== '/map') {
      setDirectAnimation(false);
    }
    if (location.pathname === '/'
      || (prevPathname === '/' && location.pathname === '/map')) {
      setStart(true);
    } else {
      setStart(false);
    }
  }, [location, prevPathname]);

  const currentTransitionClass = useCallback(() => {
    if (start) {
      return 'open';
    }
    return `${directAnimation ? 'fade' : 'reverse fade'}`;
  }, [start, directAnimation]);

  return (
    <TransitionGroup component={null}>
      <CSSTransition
        key={location.key}
        classNames={currentTransitionClass()}
        timeout={1200}
      >
        <Routes location={location}>
          {
            routes.map(displayRoute)
          }
        </Routes>
      </CSSTransition>
    </TransitionGroup>
  );
};

const App = () => (
  <>
    <div className={scss.app}>
      <BrowserRouter>
        <Switch />
      </BrowserRouter>
    </div>
  </>
);

export default App;
