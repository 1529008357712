import React, { useState, useCallback, useEffect } from 'react';
import { ZOOM_VALUES } from 'Constants';
import style from './styles/caption-layer.module.scss';

interface CaptionLayerProps {
  zoom: number;
  currentLayer: string;
}
const TOP_AJUSTMENT = '1.0%';
/* eslint-disable max-len */
const CaptionLayer :React.FC<CaptionLayerProps> = ({ zoom, currentLayer }) => {
  const [layer, setLayer] = useState<string[]>([]);
  useEffect(() => {
    setLayer([currentLayer]);
    if (currentLayer === '' && zoom === 0) {
      setLayer(['blue', 'orange', 'green', 'red', 'yellow', 'violet']);
    }
  }, [currentLayer, zoom]);

  const transform = useCallback(() => {
    let scale = (window.innerWidth / 1300);
    if (scale > 1) {
      scale = 1;
    }
    scale *= (1 / ZOOM_VALUES[zoom]);
    return `translateX(-50%) translateY(-50%) scale(${scale})`;
  }, [zoom]);
  return (
    <>
      {
        zoom === 0 && (
          <div className={style.roomNameLayer}>
            { /* BLUE */ layer.indexOf('blue') !== -1
              && (
                <div>
                  <div
                    className={`bubble ${layer.length > 1 ? 'force-white' : ''}`}
                    style={{
                      top: `calc(6.5% + ${TOP_AJUSTMENT})`,
                      left: '47.2%',
                      transform: `${transform()}`,
                    }}
                  >
                    <div>
                      <span>PRE-OPERATIVE ROOM</span>
                    </div>
                  </div>
                </div>
              )}

            { /* RED */ layer.indexOf('red') !== -1
              && (
                <div>
                  <div
                    className={`bubble ${layer.length > 1 ? 'force-white' : ''}`}
                    style={{
                      top: `calc(31.5% + ${TOP_AJUSTMENT})`,
                      left: '21.5%',
                      transform: `${transform()}`,
                    }}
                  >
                    <div>
                      <span>GENERAL FLOOR</span>
                    </div>
                  </div>
                </div>
              )}

            { /* GREEN */ layer.indexOf('green') !== -1
              && (
                <div>
                  <div
                    className={`bubble ${layer.length > 1 ? 'force-white' : ''}`}
                    style={{
                      top: `calc(36.0% + ${TOP_AJUSTMENT})`,
                      left: '77.5%',
                      transform: `${transform()}`,
                    }}
                  >
                    <div>
                      <span>POST-OPERATIVE ROOM</span>
                    </div>
                  </div>
                </div>
              )}

            { /* YELLOW */ layer.indexOf('yellow') !== -1
              && (
                <div>
                  <div
                    className={`bubble ${layer.length > 1 ? 'force-white' : ''}`}
                    style={{
                      top: `calc(46.6% + ${TOP_AJUSTMENT})`,
                      left: '52%',
                      transform: `${transform()}`,
                    }}
                  >
                    <div>
                      <span>NURSING STATION</span>
                    </div>
                  </div>
                </div>
              )}

            { /* ORANGE */ layer.indexOf('orange') !== -1
              && (
                <div>
                  <div
                    className={`bubble ${layer.length > 1 ? 'force-white' : ''}`}
                    style={{
                      top: `calc(62.5% + ${TOP_AJUSTMENT})`,
                      left: '53.5%',
                      transform: `${transform()}`,
                    }}
                  >
                    <div>
                      <span>INTENSIVE CARE UNIT</span>
                    </div>
                  </div>
                </div>
              )}

            { layer.indexOf('violet') !== -1
              && (
                <div
                  className={`bubble ${layer.length > 1 ? 'force-white' : ''}`}
                  style={{
                    top: `calc(59% + ${TOP_AJUSTMENT})`,
                    left: '40%',
                    transform: `${transform()}`,
                  }}
                >
                  <div>
                    <span>SERVER ROOM</span>
                  </div>
                </div>
              )}
          </div>
        )
      }
      { /* ZOOM > 1 */
        zoom > 0 && (
          <div className={style.captionLayer}>
            { /* BLUE */ layer.indexOf('blue') !== -1
        && (
          <div>
            <div
              className="bubble"
              style={{
                left: '44.5%',
                top: '22.75%',
                transform: `${transform()}`,
              }}
            >
              <div />
              <div className="right">
                <div>
                  <span>i</span>
                  <span>ROOT</span>
                </div>
              </div>
            </div>
            <div
              className="bubble"
              style={{
                top: '33.2%',
                left: '40%',
                transform: `${transform()}`,
              }}
            >
              <div />
              <div className="right">
                <div>
                  <span>i</span>
                  <span>UNVIEW</span>
                </div>
              </div>
            </div>
            <div
              className="bubble"
              style={{
                left: '44.55%',
                top: '28.5%',
                transform: `${transform()}`,
              }}
            >
              <div />
              <div className="right">
                <div>
                  <span>i</span>
                  <span>ISIRONA HUB</span>
                </div>
              </div>
            </div>
            { /* bed */ }
            <div
              className="bubble"
              style={{
                top: '30.5%',
                left: '47.8%',
                transform: `${transform()}`,
              }}
            >
              <div />
              <div className="right">
                <div>
                  <span>i</span>
                  <span>POINT OF<br />CARE DEVICE</span>
                </div>
              </div>
            </div>
            <div
              className="bubble"
              style={{
                top: '31%',
                left: '42.8%',
                transform: `${transform()}`,
              }}
            >
              <div />
              <div className="right">
                <div>
                  <span>i</span>
                  <span>POINT OF<br />CARE DEVICE</span>
                </div>
              </div>
            </div>
          </div>
        )}

            { /* RED */ layer.indexOf('red') !== -1
        && (
          <div>
            <div
              className="bubble"
              style={{
                top: '54%',
                left: '39%',
                transform: `${transform()}`,
              }}
            >
              <div />
              <div className="right">
                <div>
                  <span>i</span>
                  <span>ROOT</span>
                </div>
              </div>
            </div>
            <div
              className="bubble"
              style={{
                top: '56%',
                left: '38.5%',
                transform: `${transform()}`,
              }}
            >
              <div />
              <div className="right">
                <div>
                  <span>i</span>
                  <span>KITE</span>
                </div>
              </div>
            </div>
            { /* bed */ }
            <div
              className="bubble"
              style={{
                top: '56.5%',
                left: '34.8%',
                transform: `${transform()}`,
              }}
            >
              <div />
              <div className="right">
                <div>
                  <span>i</span>
                  <span>POINT OF<br />CARE DEVICE</span>
                </div>
              </div>
            </div>
            <div
              className="bubble"
              style={{
                top: '55.5%',
                left: '29.8%',
                transform: `${transform()}`,
              }}
            >
              <div />
              <div className="right">
                <div>
                  <span>i</span>
                  <span>POINT OF<br />CARE DEVICE</span>
                </div>
              </div>
            </div>
          </div>
        )}

            { /* GREEN */ layer.indexOf('green') !== -1
        && (
          <div>
            <div
              className="bubble"
              style={{
                top: '57.2%',
                left: '79.55%',
                transform: `${transform()}`,
              }}
            >
              <div />
              <div className="right">
                <div>
                  <span>i</span>
                  <span>ROOT</span>
                </div>
              </div>
            </div>
            <div
              className="bubble"
              style={{
                top: '53.7%',
                left: '70.4%',
                transform: `${transform()}`,
              }}
            >
              <div />
              <div className="right">
                <div>
                  <span>i</span>
                  <span>POINT OF<br />CARE DEVICE</span>
                </div>
              </div>
            </div>
          </div>
        )}

            { /* YELLOW */ layer.indexOf('yellow') !== -1
        && (
          <div>
            <div
              className="bubble"
              style={{
                top: '52.0%',
                left: '50.0%',
                transform: `${transform()}`,
              }}
            >
              <div />
              <div className="right">
                <div>
                  <span>i</span>
                  <span>Telehealth</span>
                </div>
              </div>
            </div>
            <div
              className="bubble"
              style={{
                top: '57%',
                left: '57.65%',
                transform: `${transform()}`,
              }}
            >
              <div />
              <div className="right">
                <div>
                  <span>i</span>
                  <span>REPLICA</span>
                </div>
              </div>
            </div>
            <div
              className="bubble"
              style={{
                top: '59.5%',
                left: '48.5%',
                transform: `${transform()}`,
              }}
            >
              <div />
              <div className="right">
                <div>
                  <span>i</span>
                  <span>IRIS ANALYTICS</span>
                </div>
              </div>
            </div>
            <div
              className="bubble"
              style={{
                top: '54.2%',
                left: '56.5%',
                transform: `${transform()}`,
              }}
            >
              <div />
              <div className="right">
                <div>
                  <span>i</span>
                  <span>PATIENT SAFETY<br />NET VIEW STATION</span>
                </div>
              </div>
            </div>
          </div>
        )}

            { /* ORANGE */ layer.indexOf('orange') !== -1
        && (
          <div>
            <div
              className="bubble"
              style={{
                top: '70.5%',
                left: '53.65%',
                transform: `${transform()}`,
              }}
            >
              <div />
              <div className="right">
                <div>
                  <span>i</span>
                  <span>KITE</span>
                </div>
              </div>
            </div>
            <div
              className="bubble"
              style={{
                top: '71.5%',
                left: '53.2%',
                transform: `${transform()}`,
              }}
            >
              <div />
              <div className="right">
                <div>
                  <span>i</span>
                  <span>ISIRONA HUB</span>
                </div>
              </div>
            </div>
            <div
              className="bubble"
              style={{
                top: '75%',
                left: '46.9%',
                transform: `${transform()}`,
              }}
            >
              <div />
              <div className="right">
                <div>
                  <span>i</span>
                  <span>3RD PARTY DEVICE</span>
                </div>
              </div>
            </div>
            {/* bed, head */}
            <div
              className="bubble"
              style={{
                top: '71%',
                left: '49%',
                transform: `${transform()}`,
              }}
            >
              <div />
              <div className="right">
                <div>
                  <span>i</span>
                  <span>POINT OF<br />CARE DEVICE</span>
                </div>
              </div>
            </div>
            { /* bed, body */ }
            <div
              className="bubble"
              style={{
                top: '74.2%',
                left: '50.5%',
                transform: `${transform()}`,
              }}
            >
              <div />
              <div className="right">
                <div>
                  <span>i</span>
                  <span>POINT OF<br />CARE DEVICE</span>
                </div>
              </div>
            </div>
            <div
              className="bubble"
              style={{
                top: '69.2%',
                left: '53%',
                transform: `${transform()}`,
              }}
            >
              <div />
              <div className="right">
                <div>
                  <span>i</span>
                  <span>ROOT</span>
                </div>
              </div>
            </div>
          </div>
        )}

            { layer.indexOf('violet') !== -1
        && (
          <div
            className="bubble"
            style={{
              top: '63.5%',
              left: '37%',
              transform: `${transform()}`,
            }}
          >
            <div />
            <div className="right">
              <div>
                <span>i</span>
                <span>MASIMO IRIS/DCX PLATEFORM</span>
              </div>
            </div>
          </div>
        )}

          </div>
        )
      }
    </>
  );
};

export default CaptionLayer;
