/* eslint-disable react/no-array-index-key */
/* eslint-disable prefer-destructuring */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import React, {
  useCallback, useEffect, useState, useRef,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { cst } from 'components/global';
import { ROOMS, ZOOM_VALUES, MAX_ZOOM } from 'Constants';
import button360 from 'assets/bouton_360.svg';
import buttonDF from 'assets/bouton_dataflow.svg';
import style from './styles/map-manager.module.scss';
import roomStyle from './styles/room.module.scss';
import Hovers from './Hovers';
import TutoMap from './TutoMap';
import CaptionLayer from './CaptionLayer';
import LottieAnimation from './LottieAnimation';

interface TitlePanelProps {
  title :string;
}
let onTapId :any;
const TitlePanel: React.FC<TitlePanelProps> = ({ title }) => (
  <>
    <div className={roomStyle.titlePanel}>
      <div>
        <span>{title}</span>
      </div>
    </div>
  </>
);

interface MapManagerProps {
  cache: any;
  onStart: () => void;
}
interface Coordinates {
  x: number;
  y: number;
}

type Status = '' | 'ready' | 'on-click' | 'on-tap' |
  'on-drag' | 'start-drag' | 'auto-drag';

const MAX_GRAB_LENGTH = 180;
const MapManager: React.FC<MapManagerProps> = ({ cache, onStart }) => {
  const [zoom, setZoom] = useState<number>(0);
  const [status, setStatus] = useState<Status>('');
  const [dragging, setDragging] = useState<number>(0);
  const [mapPosition, setMapPosition] = useState<Coordinates>({ x: 0, y: 0 });
  const [dragOrigin, setDragOrigin] = useState<Coordinates>({ x: 0, y: 0 });
  const [dragClient, setDragClient] = useState<Coordinates>({ x: 0, y: 0 });
  const [mapOut, setMapOut] = useState<Boolean>(false);
  const [limitIndex, setLimitIndex] = useState<number>(-1);
  const iCoords = { x: -50, y: -50 };
  const [clickIndicator, setClickIndicator] = useState<Coordinates>(iCoords);
  const [dragIndicator, setDragIndicator] = useState<Coordinates>(iCoords);
  const [showDataFlow, setShowDataFlow] = useState<boolean>(false);
  const [currentDestination, setCurrentDestination] = useState<string>('');
  const isReady: boolean = sessionStorage.getItem('map:readyToUse') === 'true';
  const [unselectArea, setUnselectArea] = useState<any>({ value: 0 });
  const [readyToUse, setReadyToUse] = useState<boolean>(isReady);
  const [tutoIndex, setTutoIndex] = useState<number>(isReady ? 3 : 0);
  const [currentHover, setCurrentHover] = useState<string>('');
  const [navigating, setNavigating] = useState<boolean>(false);

  const navigate = useNavigate();
  const map: any = useRef(null);
  const layers: any = useRef(null);
  const limits: any[] = Array(4).fill(
    () => useRef(null),
  ).map((value) => value());

  const applyTransformation = (
    layersEl: any, z: number, x: number, y: number,
  ) => {
    layersEl.style.transform = `
      scale(${z})
      translate(${x}px, ${y}px)
    `;
  };

  useEffect(() => {
    // setStatus('on-click');
    resetZoom();
    return () => {
      setNavigating(false);
      setZoom(0);
      setStatus('');
      setDragging(0);
      setMapPosition({ x: 0, y: 0 });
      setDragOrigin({ x: 0, y: 0 });
      setDragClient({ x: 0, y: 0 });
      setMapOut(false);
      setLimitIndex(-1);
      setClickIndicator(iCoords);
      setDragIndicator(iCoords);
      setShowDataFlow(false);
      setCurrentDestination('');
      setUnselectArea({ value: 0 });
      setReadyToUse(isReady);
      setTutoIndex(isReady ? 3 : 0);
      setCurrentHover('');
    };
  }, []);

  useEffect(() => {
    setStatus('ready');
    const resizer = () => {
      const layersEl: HTMLDivElement = layers?.current;
      if (layersEl) {
        setZoom(0);
        setMapPosition({ x: 0, y: 0 });
        applyTransformation(layersEl, ZOOM_VALUES[0], 0, 0);
      }
    };
    window.addEventListener('resize', resizer);
    return () => {
      window.removeEventListener('resize', resizer);
    };
  }, [layers]);

  const checkLimitInconsistencies = (l:any[]) => {
    if (l && l[0] && l[1] && l[2] && l[3] && l[0].coord) {
      if (l[0].coord.x < 0 && l[1].coord.x > window.innerWidth
        && l[2].coord.x < 0 && l[3].coord.x > window.innerWidth
        && l[0].coord.y < 0 && l[2].coord.y > window.innerHeight
        && l[1].coord.y < 0 && l[3].coord.y > window.innerHeight
      ) {
        l[0].out = false;
        l[1].out = false;
        l[2].out = false;
        l[3].out = false;
        return;
      }
      if (l[0].coord.y > 0 && l[0].coord.y < window.innerHeight
        && l[1].coord.y > 0 && l[1].coord.y < window.innerHeight
        && l[0].coord.x < 0 && l[1].coord.x > window.innerWidth) {
        l[0].out = false;
        cache.ci.splice(0, 1);
      }
      if (l[2].coord.y > 0 && l[2].coord.y < window.innerHeight
        && l[3].coord.y > 0 && l[3].coord.y < window.innerHeight
        && l[2].coord.x < 0 && l[3].coord.x > window.innerWidth) {
        l[2].out = false;
        cache.ci.splice(2, 1);
      }
      if (l[0].coord.x > 0 && l[0].coord.x < window.innerWidth
        && l[2].coord.x > 0 && l[2].coord.x < window.innerWidth
        && l[0].coord.y < 0 && l[2].coord.y > window.innerHeight) {
        l[0].out = false;
        cache.ci.splice(0, 1);
      }
      if (l[1].coord.x > 0 && l[1].coord.x < window.innerWidth
        && l[3].coord.x > 0 && l[3].coord.x < window.innerWidth
        && l[1].coord.y < 0 && l[3].coord.y > window.innerHeight) {
        l[1].out = false;
        cache.ci.splice(1, 1);
      }
    }
  };

  const checkLimits = useCallback(() => {
    const l: Array<any> = [];
    limits.forEach((limit, index) => {
      const data = {
        out: false, coord: { x: 0, y: 0 },
      };
      if (limit?.current) {
        const el: HTMLDivElement = limit.current;
        const {
          top, bottom, left, right,
        } = el.getBoundingClientRect();
        data.coord = {
          x: left + (right - left) / 2,
          y: top + (bottom - top) / 2,
        };
        if ((data.coord.x > window.innerWidth
          || data.coord.y > window.innerHeight
          || data.coord.x < 0 || data.coord.y < 0)) {
          data.out = true;
          const found:number | undefined = cache.ci.find(
            (value: any) => value === index,
          );
          if (found === undefined) {
            cache.ci.push(index);
          }
        } else {
          const foundIndex:number = cache.ci.findIndex(
            (value: any) => value === index,
          );
          if (foundIndex !== -1) {
            cache.ci.splice(foundIndex, 1);
          }
        }
        l[index] = data;
      }
    });
    checkLimitInconsistencies(l);
    setLimitIndex(-1);
    if (l.length && cache.prevCILength
        && l[0].out && l[1].out && l[2].out && l[3].out) {
      setLimitIndex(cache.ci[cache.ci.length - 1]);
      setMapOut(true);
    } else {
      setMapOut(false);
    }
    cache.prevCILength = cache.ci.length <= 4 && cache.ci.length > 0;
  }, [mapOut]);

  // drag back automatically
  useEffect(() => {
    if (mapOut) {
      if (limitIndex !== -1) {
        const el: HTMLDivElement = limits[limitIndex].current;
        const {
          top, bottom, left, right,
        } = el.getBoundingClientRect();
        const coord: Coordinates = {
          x: left + (right - left) / 2,
          y: top + (bottom - top) / 2,
        };
        const f: number = 0.5;
        const width: number = Math.abs(right - left) * f;
        const height: number = Math.abs(bottom - top) * f;
        const translate: Coordinates = { x: 0, y: 0 };
        if (coord.x > window.innerWidth) {
          translate.x = -(width + (coord.x - window.innerWidth));
        } else if (coord.x < 0) {
          translate.x = width - coord.x;
        }
        if (coord.y > window.innerHeight) {
          translate.y = -(height + (coord.y - window.innerHeight));
        } else if (coord.y < 0) {
          translate.y = height - coord.y;
        }
        setDragging(3);
        setStatus('auto-drag');
        move({ x: translate.x / 2, y: translate.y / 2 }).then(() => {
          setDragging(0);
          setStatus('ready');
        });
      }
    }
  }, [mapOut, limitIndex]);

  useEffect(() => {
    let id: any;
    if (status === 'ready') {
      id = setTimeout(() => {
        checkLimits();
        clearTimeout(id);
      }, 1000);
    }
    return () => {
      clearTimeout(id);
    };
  }, [status]);

  useEffect(() => {
    let id: any;
    if (dragging === 1) {
      id = setTimeout(() => {
        if (dragging === 1) {
          setDragging(2);
          setDragIndicator({ x: dragOrigin.x, y: dragOrigin.y });
          setStatus('start-drag');
        }
        clearTimeout(id);
      }, 200);
    }
    return () => {
      clearTimeout(id);
    };
  }, [dragging, dragOrigin]);

  useEffect(() => {
    let id: any;
    if (status === 'on-click') {
      id = setTimeout(() => {
        setStatus('ready');
        clearTimeout(id);
      }, 200);
    } else if (status === 'on-tap') {
      id = setTimeout(() => {
        setStatus('ready');
        clearTimeout(id);
      }, 200);
    }
    return () => {
      clearTimeout(id);
    };
  }, [status]);

  const onHover = useCallback((id: string) => {
    setCurrentHover(id);
  }, []);

  const move = useCallback((translate: Coordinates) => {
    translate.x /= ZOOM_VALUES[zoom] / cst.DRAG_VALUE;
    translate.y /= ZOOM_VALUES[zoom] / cst.DRAG_VALUE;
    const layersEl: HTMLDivElement = layers.current;
    const position = {
      x: translate.x + mapPosition.x,
      y: translate.y + mapPosition.y,
    };
    setMapPosition(position);
    if (layers.current !== null) {
      const { x, y } = position;
      applyTransformation(layersEl, ZOOM_VALUES[zoom], x, y);
    }
    return Promise.resolve(true);
  }, [layers, zoom, mapPosition]);

  const drag = useCallback(() => {
    const mapEl: HTMLDivElement = map.current;
    if (mapEl !== null) {
      const translate: Coordinates = {
        x: dragClient.x - dragOrigin.x,
        y: dragClient.y - dragOrigin.y,
      };
      translate.x /= ZOOM_VALUES[zoom] / cst.DRAG_VALUE;
      translate.y /= ZOOM_VALUES[zoom] / cst.DRAG_VALUE;
      const layersEl: HTMLDivElement = layers.current;
      const position = {
        x: translate.x + mapPosition.x,
        y: translate.y + mapPosition.y,
      };
      setMapPosition(position);
      if (layers.current !== null) {
        const { x, y } = position;
        applyTransformation(layersEl, ZOOM_VALUES[zoom], x, y);
      }
    }
    return Promise.resolve(true);
  }, [map, layers, zoom, mapPosition, dragOrigin, dragClient]);

  const zoomInFrom = useCallback((e: any) => {
    const mapEl: HTMLDivElement = map.current;
    if (mapEl !== null) {
      const layersEl: HTMLDivElement = layers.current;
      if (layersEl !== null) {
        const scale: number = ZOOM_VALUES[zoom + 1];
        const rect: any = mapEl.getBoundingClientRect();
        const mwidth = rect.width / 2;
        const mheight = rect.height / 2;
        const mouse: Coordinates = {
          x: ((e.clientX - rect.left) - mwidth) / ZOOM_VALUES[zoom],
          y: ((e.clientY - rect.top) - mheight) / ZOOM_VALUES[zoom],
        };
        const translate: Coordinates = {
          x: -mouse.x + mapPosition.x,
          y: -mouse.y + mapPosition.y,
        };
        setMapPosition({
          x: translate.x,
          y: translate.y,
        });
        if ((zoom + 1) <= MAX_ZOOM) {
          setZoom(zoom + 1);
          const { x, y } = translate;
          applyTransformation(layersEl, scale, x, y);
        }
      }
    }
  }, [map, layers, zoom, mapPosition]);

  const resetZoom = useCallback(() => {
    const layersEl: HTMLDivElement = layers.current;
    if (layersEl !== null) {
      setZoom(0);
      setMapPosition({ x: 0, y: 0 });
      applyTransformation(layersEl, ZOOM_VALUES[0], 0, 0);
    }
    setStatus('on-click');
    setDragging(0);
    setUnselectArea({ value: 1 });
  }, [unselectArea]);

  const zoomIn = useCallback(() => {
    const mapEl: HTMLDivElement = map.current;
    if (mapEl !== null) {
      const layersEl: HTMLDivElement = layers.current;
      if (layersEl !== null) {
        const scale: number = ZOOM_VALUES[zoom + 1];
        if ((zoom + 1) <= MAX_ZOOM) {
          setZoom(zoom + 1);
          const { x, y } = mapPosition;
          setMapPosition({ x, y });
          applyTransformation(layersEl, scale, x, y);
        }
      }
    }
  }, [map, layers, zoom, mapPosition]);

  const zoomOut = useCallback(() => {
    const mapEl: HTMLDivElement = map.current;
    if (mapEl !== null) {
      const layersEl: HTMLDivElement = layers.current;
      if (layersEl !== null) {
        const scale: number = ZOOM_VALUES[zoom - 1];
        if ((zoom - 1) === 0) {
          resetZoom();
        } else if ((zoom - 1) > 0) {
          setZoom(zoom - 1);
          const { x, y } = mapPosition;
          setMapPosition({ x, y });
          applyTransformation(layersEl, scale, x, y);
        }
      }
    }
  }, [map, layers, zoom, mapPosition]);

  const DataFlowButton = useCallback((props) => {
    const { onActivate } = props;
    return (
      <button
        type="button"
        className={
          `${style.dataFlowButton} ${showDataFlow ? style.DFActive : ''}`
        }
        onClick={(e) => {
          e.stopPropagation();
          if (readyToUse) {
            onActivate(e);
          }
        }}
      >
        <span
          className="inner"
          style={{
            backgroundImage: `url(${buttonDF})`,
          }}
        />
      </button>
    );
  }, [showDataFlow, readyToUse]);

  /* todo: add real dataflows */
  const DataFlow = useCallback(() => (
    <>
      { showDataFlow && (
        <>
          { zoom === 0 && (
            <>
              { currentHover === 'blue'
                  && <LottieAnimation json="data-flow.json" /> }
              { currentHover === 'violet'
                  && <LottieAnimation json="data-flow.json" /> }
              { currentHover === 'yellow'
                  && <LottieAnimation json="data-flow.json" /> }
              { currentHover === 'green'
                  && <LottieAnimation json="data-flow.json" /> }
              { currentHover === 'red'
                  && <LottieAnimation json="data-flow.json" /> }
              { currentHover === 'orange'
                  && <LottieAnimation json="data-flow.json" /> }
            </>
          )}
          { zoom > 0 && (
            <>
              { currentHover === 'blue'
                  && <LottieAnimation json="data-flow.json" /> }
              { currentHover === 'violet'
                  && <LottieAnimation json="data-flow.json" /> }
              { currentHover === 'yellow'
                  && <LottieAnimation json="data-flow.json" /> }
              { currentHover === 'green'
                  && <LottieAnimation json="data-flow.json" /> }
              { currentHover === 'red'
                  && <LottieAnimation json="data-flow.json" /> }
              { currentHover === 'orange'
                  && <LottieAnimation json="data-flow.json" /> }
            </>
          )}
        </>
      )}
    </>
  ), [zoom, showDataFlow, currentHover]);

  const Switch360Button = useCallback(() => (
    <button
      type="button"
      className={style.switchButton360}
      disabled={navigating}
      style={{
        opacity: `${navigating ? 0.5 : (currentDestination !== '' ? 1 : 0.0)}`,
      }}
      onClick={
        () => {
          if (currentDestination !== '') {
            navigate(currentDestination);
            setNavigating(true);
            onStart();
          }
        }
      }
    >
      <span
        className="inner"
        style={{
          backgroundImage: `url(${button360})`,
        }}
      />
    </button>
  ), [currentDestination, navigating]);

  const ZoomButtons = useCallback(() => (
    <div
      tabIndex={0}
      role="button"
      className="zoom-button"
      onClick={(e :any) => {
        e.stopPropagation();
      }}
    >
      <button
        disabled={!readyToUse || zoom === 0}
        style={{
          opacity: `${zoom > 0 ? 1 : 0.5}`,
          cursor: `${zoom > 0 ? 'pointer' : 'default'}`,
        }}
        type="button"
        onClick={() => {
          if (zoom > 0) {
            zoomIn();
            setZoomStatus('on-zoom');
          }
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 33 33"
        >
          <g
            id="Groupe_121"
            data-name="Groupe 121"
            transform="translate(-1824.5 -130.5)"
          >
            <path
              id="Tracé_2"
              data-name="Tracé 2"
              d="M1827-957.5h28"
              transform="translate(0 1104.5)"
              fill="none"
              stroke="#999"
              strokeLinecap="round"
              strokeWidth="5"
            />
            <path
              id="Tracé_3"
              data-name="Tracé 3"
              d="M1827-957.5h28"
              transform="translate(883.5 -1694) rotate(90)"
              fill="none"
              stroke="#999"
              strokeLinecap="round"
              strokeWidth="5"
            />
          </g>
        </svg>
      </button>
      <button
        disabled={!readyToUse || zoom === 0}
        style={{
          opacity: `${zoom > 0 ? 1 : 0.5}`,
          cursor: `${zoom > 0 ? 'pointer' : 'default'}`,
        }}
        type="button"
        onClick={() => {
          if (zoom > 0) {
            zoomOut();
            setZoomStatus('on-zoom');
          }
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="3"
          viewBox="0 0 33 5"
        >
          <g
            id="Groupe_107"
            data-name="Groupe 107"
            transform="translate(-1824.5 -144.5)"
          >
            <path
              id="Tracé_2"
              data-name="Tracé 2"
              d="M1827-957.5h28"
              transform="translate(0 1104.5)"
              fill="none"
              stroke="#999"
              strokeLinecap="round"
              strokeWidth="5"
            />
          </g>
        </svg>
      </button>
    </div>
  ), [
    map, layers, zoom, mapPosition, readyToUse,
  ]);

  const DragIndicator = useCallback(() => {
    if (status === 'start-drag' || status === 'on-drag') {
      let width: number = +(Math.abs(dragIndicator.x
        - dragClient.x) * 2).toFixed(2);
      let height: number = +(Math.abs(dragIndicator.y
        - dragClient.y) * 2).toFixed(2);
      const x: number = +(dragClient.x - dragIndicator.x).toFixed(2);
      const y: number = +(dragClient.y - dragIndicator.y).toFixed(2);
      const x2: number = +(width / 2 + x).toFixed(2);
      const y2: number = +(height / 2 + y).toFixed(2);
      if (width < 2) {
        width = 2;
      }
      if (height < 2) {
        height = 2;
      }
      return (
        <>
          {
            (status === 'start-drag' || status === 'on-drag')
            && (
              <div
                style={{
                  left: `calc(${dragIndicator.x}px - 10px)`,
                  top: `calc(${dragIndicator.y}px - 10px)`,
                }}
                className="drag-indicator active"
              >
                {
                  status === 'on-drag'
                && (
                  <>
                    <div
                      className="head"
                      style={{
                        left: `${x}px`,
                        top: `${y}px`,
                      }}
                    />
                    <svg style={{
                      width: `${width}px`,
                      height: `${height}px`,
                    }}
                    >
                      <line
                        x1="50%"
                        y1="50%"
                        x2={x2}
                        y2={y2}
                        strokeWidth="2"
                      />
                    </svg>
                  </>
                )
                }
              </div>
            )
          }
        </>
      );
    }
    return <></>;
  }, [dragIndicator, dragClient, status]);

  const ClickIndicator = useCallback(() => (
    <>
      <div
        style={{
          left: `calc(${clickIndicator.x}px - 25px)`,
          top: `calc(${clickIndicator.y}px - 25px)`,
        }}
        className="click-indicator active"
      />
    </>
  ), [clickIndicator]);

  const updateMouseOnClick = useCallback((e :any, t: any) => {
    const mapEl: HTMLDivElement = map.current;
    if (mapEl !== null) {
      const rect: any = mapEl.getBoundingClientRect();
      const mouse: Coordinates = t !== undefined ? {
        x: t.x - rect.left,
        y: t.y - rect.top,
      } : {
        x: e.clientX - rect.left,
        y: e.clientY - rect.top,
      };
      return Promise.resolve(mouse);
    }
    return Promise.reject();
  }, [map]);

  const layerRoomMapping: any = {
    red: { name: 'room1' },
    orange: { name: 'room2' },
    yellow: { name: 'room3' },
    green: { name: 'room4' },
    blue: { name: 'room5' },
    violet: { name: 'room6' },
  };

  const winMouseUp = useCallback(() => {
    if (status === 'on-drag' || status === 'start-drag') {
      setStatus('ready'); // on-click
      setDragging(0);
    }
  }, [status]);

  useEffect(() => {
    const keyup: any = window.addEventListener('mouseup', winMouseUp);
    return () => {
      window.removeEventListener('mouseup', keyup);
    };
  }, [winMouseUp]);

  const [zoomStatus, setZoomStatus] = useState<string>('');
  let zoomTimeId: any;
  useEffect(() => {
    if (zoomStatus === 'on-zoom') {
      clearTimeout(zoomTimeId);
      setTimeout(() => {
        clearTimeout(zoomTimeId);
        setZoomStatus('');
      }, 1000);
    }
    return () => {
      clearTimeout(zoomTimeId);
    };
  }, [zoomStatus]);

  const onEnter = useCallback((e :any) => {
    clearTimeout(onTapId);
    if (readyToUse) {
      if (status !== 'on-tap') {
        if (status === 'ready') {
          setZoomStatus('on-zoom');
          zoomInFrom(e);
          updateMouseOnClick(e, undefined).then((mouse: Coordinates) => {
            setClickIndicator(mouse);
          });
        }
        setStatus('on-click');
        setDragging(0);
      }
    }
  }, [readyToUse, onTapId, status]);

  const adjustMouseClient = (e :any) => {
    const t :any = { x: e.clientX, y: e.clientY };
    if (e.changedTouches !== undefined) {
      const touch = e.changedTouches[0];
      if (touch !== undefined) {
        t.x = touch.clientX;
        t.y = touch.clientY;
      }
    }
    return t;
  };

  const onMouseDown = (e :any, isOnTouch :boolean) => {
    const t = adjustMouseClient(e);
    if (readyToUse && zoom > 0) {
      setDragging(1);
      updateMouseOnClick(e, t).then((mouse: Coordinates) => {
        setDragOrigin(mouse);
        setDragClient(mouse);
      });
    } else if (zoom === 0 && isOnTouch) {
      onTapId = setTimeout(() => {
        setStatus('on-tap');
        clearTimeout(onTapId);
      }, 500);
    }
  };

  const onMouseUp = () => {
    if (readyToUse && zoom > 0) {
      if (status === 'on-drag') {
        drag().then(() => {
          setDragging(0);
        });
      } else {
        setDragging(0);
        setStatus('ready');
      }
    }
  };

  const onMouseLeave = () => {
    if (readyToUse && zoom > 0) {
      setDragging(0);
    }
  };

  const onMouseMove = (e :any) => {
    const t = adjustMouseClient(e);
    if (readyToUse && zoom > 0) {
      if (dragging === 2) {
        setStatus('on-drag');
        let x = t.x - dragOrigin.x;
        let y = t.y - dragOrigin.y;
        const a :number = Math.abs(x);
        const b :number = Math.abs(y);
        const n :number = Math.sqrt(a * a + b * b);
        if (n > MAX_GRAB_LENGTH) {
          x = (x / n) * MAX_GRAB_LENGTH;
          y = (y / n) * MAX_GRAB_LENGTH;
        }
        setDragClient({ x: dragOrigin.x + x, y: dragOrigin.y + y });
      }
    }
  };
  return (
    <div
      id="map"
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      <div className={style.isoContainer}>
        {
          tutoIndex === 0
          && (
            <TutoMap
              full={false}
              onClosed={() => {
                setTutoIndex(3);
                setReadyToUse(true);
                sessionStorage.setItem('map:readyToUse', 'true');
              }}
            />
          )
        }
        {
          tutoIndex === 2
          && (
            <TutoMap
              full
              onClosed={() => {
                setTutoIndex(3);
              }}
            />
          )
        }
        { currentHover !== '' && zoom > 0
          && (
            <TitlePanel
              title={ROOMS[layerRoomMapping[currentHover].name].title}
            />
          )}
        <ZoomButtons />
        <Switch360Button />
        <DataFlowButton
          onActivate={
            () => {
              setShowDataFlow(!showDataFlow);
            }
          }
          enabled={showDataFlow}
        />
        <div
          ref={map}
          className={`map ${zoomStatus === 'on-zoom' ? 'no-cross' : ''}`}
          role="button"
          tabIndex={0}
          onMouseMove={(e: any) => {
            onMouseMove(e);
          }}
          onMouseUp={() => {
            onMouseUp();
          }}
          onMouseLeave={() => {
            onMouseLeave();
          }}
          onMouseDown={(e: any) => {
            onMouseDown(e, false);
          }}
          onTouchStart={(e: any) => {
            onMouseDown(e, true);
          }}
          onTouchEnd={() => {
            onMouseUp();
            setStatus('ready');
          }}
          onTouchMove={(e: any) => {
            onMouseMove(e);
          }}
          onClick={() => {
            if (zoom === 0) {
              setUnselectArea({ value: 1 });
            }
            if (readyToUse && zoom > 0) {
              setStatus('on-click');
              setDragging(0);
            }
          }}
        >
          <ClickIndicator />
          <DragIndicator />
          <div
            className="layers"
            ref={layers}
            onDragStart={(e: any) => {
              e.preventDefault();
            }}
          >
            <CaptionLayer zoom={zoom} currentLayer={currentHover} />
            { readyToUse
              && (
                <Hovers
                  status={status}
                  unselectArea={unselectArea}
                  zoom={zoom}
                  onHover={onHover}
                  onSelect={(value, e) => {
                    if (e !== null && zoom === 0) {
                      onEnter(e);
                    }
                    setCurrentDestination(value);
                    if (value !== '') {
                      setTutoIndex(2);
                    } else {
                      setTutoIndex(3);
                    }
                  }}
                />
              )}
            <DataFlow />
            {Array(4).fill(0).map(
              (_, index) => (
                <div
                  key={index}
                  ref={limits[index]}
                  className={`limit _${index}`}
                />
              ),
            )}
            <img
              src="./img/map.png"
              alt=""
              draggable="false"
              className="background"
              unselectable="on"
              onDragStart={(e: any) => {
                e.preventDefault();
              }}
              onMouseDown={(e: any) => {
                e.preventDefault();
              }}
              onClick={(e: any) => {
                e.preventDefault();
              }}
            />
            <img
              src="./img/background.svg"
              alt=""
              draggable="false"
              className="big-background"
              unselectable="on"
              onDragStart={(e: any) => {
                e.preventDefault();
              }}
              onMouseDown={(e: any) => {
                e.preventDefault();
              }}
              onClick={(e: any) => {
                e.preventDefault();
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MapManager;
