import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import masimoLogo from 'assets/logo_masimo.svg';
import style from './styles/splash-screen.module.scss';

const SplashScreen: React.FC<any> = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const id: any = setTimeout(() => {
      navigate('/map');
      clearTimeout(id);
    }, 2000);
    return () => {
      clearTimeout(id);
    };
  }, []);
  return (
    <div className={style.splashScreen}>
      <span
        style={{
          backgroundImage: `url(${masimoLogo})`,
        }}
      />
    </div>
  );
};
export default SplashScreen;
