/*
import React from 'react';
import { hydrate, render } from 'react-dom';
import reportWebVitals from './reportWebVitals';
import './index.css';
import App from './App';

const rootElement :any = document.getElementById('root');
if (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement);
} else {
  render(<App />, rootElement);
}
*/
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <App />,
  document.getElementById('root'),
);
reportWebVitals();
