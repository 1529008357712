import React, { useEffect, useState } from 'react';
import { BUBBLE_TIMEOUT_2 } from 'Constants';
import style from './styles/tuto-map.module.scss';

interface TutoMapProps {
  // eslint-disable-next-line no-unused-vars
  onClosed: () => void;
  full: boolean;
}
const TutoMap: React.FC<TutoMapProps> = ({ onClosed, full }) => {
  const [visible, setVisible] = useState<boolean>(true);

  useEffect(() => {
    const id = setTimeout(() => {
      clearTimeout(id);
      setVisible(false);
      onClosed();
    }, BUBBLE_TIMEOUT_2);
    return () => {
      clearTimeout(id);
    };
  }, []);

  return (
    <>
      {
        visible
        && (
          <div
            className={`${style.tutoMap} ${style.tutoMapAnimated}`}
            style={{
              pointerEvents: `${full ? 'none' : 'auto'}`,
            }}
          >
            { full
              && (
                <div>
                  <span>Open the 360° view</span>
                </div>
              )}
            { !full
              && (
                <div style={{ visibility: 'hidden' }}>
                  <span />
                </div>
              )}
            <div>
              <span>Active the data flow animation</span>
            </div>
            <div>
              <span>Zoom-in/zoom-out</span>
            </div>
          </div>
        )
      }
    </>
  );
};

export default TutoMap;
